<template>
  <v-app>
    <eps-login-header></eps-login-header>
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar class="tc-title" dark flat>
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.uid"
                    prepend-icon="email"
                    placeholder="Enter Your Username"
                    clearable
                    required
                  ></v-text-field>
                  <v-text-field
                    
                    v-model="user.pass"
                    :rules="[v => !!v || 'password required']"
                    prepend-icon="lock"
                    placeholder="Enter Your Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    required
                  ></v-text-field>
                  <!-- <v-btn class="mr-4" @click="clear">Reset</v-btn> -->
                  <center>
                  <v-btn  class="mr-4 btn-title" color="primary" :loading="loading" @click="signIn">Login</v-btn>
                  </center>
                </v-form>
                <br />

                <v-row justify="space-between">
                  <router-link to="/forgot-password">Forgot Password?</router-link>
                  <router-link  to="/alumini-signup">Alumini Sign-UP</router-link>
                  
                </v-row>
                <v-col cols="12">
                  <div v-show="message!=null">
                  <v-alert
                    outlined
                    type="error"
                    prominent
                    border="left"
                    dismissible
                  >{{message}}
                  </v-alert>
                  </div>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Eduplus Campus
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="readPolicy()"
            :loading="read_policy_load"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    </v-content>
    <eps-login-footer></eps-login-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import crypto from '../service/crypto'
import epsLoginHeader from "./LoginHeader.vue";
import epsLoginFooter from "./LoginFooter.vue";
export default {
  components: {
    epsLoginHeader: epsLoginHeader,
    epsLoginFooter: epsLoginFooter
  },
  data() {
    return {
      user: {
        uid: "",
        pass: ""
      },
      value: String,
      message: "",
      dialog: false,
      loading: false,
      read_policy_load:false,
      rules: {
        required: value => !!value || "Required.",
        uid: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
    };
  },
  methods: {
    readPolicy(){
      this.read_policy_load=true
      axios
          .post("/login/readPolicy", this.user)
          .then(res => {
         //   window.console.log("res" + res.data.msg);
            if (res.data.msg == "200") {
                this.$router.push("/home");
              }
            })
            .catch(error => {
            window.console.log(error);
            this.message = "Something went wrong";
          })
          .finally(()=>{
            this.dialog = false
            this.read_policy_load = false
          });
    },
    signIn() {
       
      if (this.perormLoginFormValidation()) {
        let enc = crypto.encrypt(this.user);
      //  console.log("aaaaaa - "+enc)
        this.user.dns = window.location.hostname;
        const data = {
          params: enc
        };
        this.loading=true;
        axios
          .post("/login/process", data)
          .then(res => {
            // window.console.log("res" + res.data.policy_read);
            // window.console.log("res.data.msg " + res.data.msg);
           
            if (res.data.msg == "200") {
              this.loading=false;
              localStorage.setItem("EPS-token", res.data.token);
              localStorage.setItem("EPS-uid",crypto.encrypt(res.data.uid));
              localStorage.setItem("EPS-tenant", crypto.encrypt(res.data.tenant));
              localStorage.setItem("EPS-usertype", crypto.encrypt(res.data.usertype));               
              if(res.data.policy_read==true)
                this.$router.push("/home");
              else{
                  this.dialog = true
              }
              //window.location="/home"
            }else if(res.data.msg == "superadmin") 
            {
              this.loading=false;
              localStorage.setItem("awspath",res.data.awspath)
              localStorage.setItem("EPS-token", res.data.token);
              localStorage.setItem("EPS-uid", res.data.uid);
              // localStorage.setItem("EPS-tenant", res.data.uid);
                 this.$router.push("/admin-supermasters");
             
            }
            else {
              this.loading=false;
              this.message = "Please check ur credentials!!!";
              localStorage.setItem("EPS-token", "");
              localStorage.setItem("EPS-uid", "");
              localStorage.setItem("EPS-tenant","");
              localStorage.setItem("awspath","")
            }
          })
          .catch(error => {
            // window.console.log(error);
            this.loading=false;
            this.message = "Something went wrong";
          });
      } else {
        // console.log("I am in else block");
        this.loading=false;
      }
    },
    clear() {
      this.user.uid = "";
      this.user.pass = "";
    },
    perormLoginFormValidation() {
      if (this.user.uid == "" && this.user.pass == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    // let enc = crypto.encrypt("reena");
    // console.log("in mounted");
    // console.log(enc);
    this.message = localStorage.getItem("login-page-msg");
    // console.log("login-page-msg>>"+localStorage.getItem("login-page-msg"));

    this.$store.commit('setquick_links', false);
    localStorage.clear();
  }
};
</script>
</script>
<style scoped>
 .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .btn-title{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
.pull-right {
  position: relative;
  text-align: center;
}
</style>